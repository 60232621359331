<template>
  <div>
    <!--    表格-->
    <div class="class-table">
      <div class="search-con">
        <!-- <el-button type="danger" @click="batchDeleteClick()">批量删除</el-button> -->
        <el-input class="search-class" placeholder="请输入内容" prefix-icon="el-icon-search" v-model="nickName"
          @keyup.enter.native="search">
        </el-input>
        <el-button type="primary" plain @click="search">搜索</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" @select=select @select-all=selectAll>
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column label="充值序号">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.id }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="充值用户">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.nickName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="充值金额">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.rechargeAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="充值方式">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.paymentMethod }}</span>
          </template>
        </el-table-column>
        <el-table-column label="充值时间">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.createTime | dateFormat }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-sizes="[1, 10, 20, 30, 40]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change=sizeChange
        @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getList } from "../../network/recharge";

export default {
  name: "AdminRecharge",
  data() {
    return {
      pageSize: 10,
      total: 0,
      currentPage: 1,
      tableData: [],
      addFormIsShow: false,
      addForm: {},
      replyRow: {},
      selectRow: [],
      nickName: ''
    }
  },
  created() {
    const req = {
      currentPage: this.currentPage,
      pageSize: this.pageSize
    }
    getList(req).then(res => {
      this.tableData = res.data.records
      this.total = res.data.size
    })
  },
  methods: {
    select(selection, row) {
      this.selectRow = selection
    },
    search() {
      this.getTableData()
    },
    getTableData() {
      //请求表格数据
      const req = {}
      req.name = this.nickName
      req.currentPage = this.currentPage
      req.pageSize = this.pageSize
      getList(req).then(res => {
        this.tableData = res.data.records
        this.pageSize = res.data.size
        this.currentPage = res.data.current
        this.total = res.data.total
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.search-class {
  width: 200px;
  padding-right: 4px;
  margin-left: 10px;
}
</style>