import {request} from "./request";

export function getList(req) {
    return request({
        method: 'post',
        url: '/recharge/list',
        data:{
            "currentPage": req.currentPage,
            "name":req.name,
            "pageSize": req.pageSize
        }
    })
}